import React, { useState } from "react";
import { Transition } from "@headlessui/react";


function Nav() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <nav className="bg-danger border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <a href="/" target="_blank" class="flex items-center">
                  <img
                    src=".././images/logo_cinef_ok.png"
                    class="w-48"
                    alt="Logo CINEF"
                  />
                </a>
              </div>
              <div className="hidden md:block">
                <div className="ml-0 flex items-baseline space-x-4">
                  <a
                    href="/"
                    className="text-green-700 border-solid border-2 border-green-600 px-4 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                  >
                    Accueil
                  </a>

                  <a
                    href="/blogs"
                    className="text-green-700 border-solid border-2 border-green-600 px-4 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                  >
                    A propos
                  </a>

                  <a
                    href="/contact"
                    className="text-green-700 border-solid border-2 border-green-600 px-4 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                  >
                    Contact
                  </a>
                  <a
                    href="/login"
                    className="text-white bg-green-700 font-bold hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm  "
                  >
                    Se connecter 🔐
                  </a>
                  <div className="text-right pl-48">
                    <a
                      href="https://www.cinef-bj.com/formations/docs/catalogue_cinef_2025.pdf"
                      target="_blank"
                      className="text-center text-white bg-green-700 font-bold hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base "
                    >
                      Catalogue de formation 2025 📝
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="-mr-2 flex md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="bg-gray-900 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div className="md:hidden" id="mobile-menu">
              <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                <a
                  href="/"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Accueil
                </a>

                <a
                  href="/blogs"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  A propos
                </a>

                <a
                  href="/contact"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Contact
                </a>

                <a
                  href="https://www.cinef-bj.com/formations/docs/catalogue_cinef_2023_2024.pdf"
                  target="_blank"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Catalogue 2023-2024
                </a>
                
              </div>
              
            </div>
            
          )}
          
        </Transition>
        </nav>
    </div>
  );
}

export default Nav;
