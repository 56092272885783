import React from "react";
import Header from "../Header";
import { useState, useEffect } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import Protedroute from "./Protedroute";
import axios from "axios";
//import { useTableSearch } from "./useTableSearch";
//jQuery libraries

import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
//import "https://cdn.datatables.net/1.13.1/css/jquery.dataTables.min.css";
import $ from 'jquery'; 



export default function Userdash() {
  const [darkToggle, setDarkToggle] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [succesmsg, setSuccesmsg] = React.useState("");
  const [lieu, setLieu] = useState("");
  const [theme, setTheme] = useState("");
  const [periode, setPeriode] = useState("");
  const [code, setCode] = useState("");
  const [formateursdata, setFormateursData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [certId, setCertId] = useState("");
  const [errormsg, setErrormsg] = React.useState("");

  const certificatUrl = process.env.React_App_Uri_api + "formateurs";

  const [searchVal, setSearchVal] = useState(null);

  // alert(searchVal);
  const navigate = useNavigate();

  //Generate
  const ref = (Math.random() + 1).toString(36).substring(7);
  //console.log("réf", ref);

  //date
  const current = new Date();
  const ondate = current.toLocaleString();
  //const ondate = Date();
  //console.log(ondate);

  const location = useLocation();
  /*console.log(location);*/

  const user = sessionStorage.getItem("UserName");
  /*console.log(user);*/

  /*CheckUserIdentity*/
  const checkUserprofile = () => {
    const userInfo = sessionStorage.getItem("UserName");
    if (userInfo === null) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/login");
    }
  };
  useEffect(() => {
    checkUserprofile();
  });

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
  };

  //GET ALL ATTESTION
const getallCertiData= () => {
  axios
    .get(certificatUrl, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-token")}`,
      },
    })
    .then(function (response) {
      //console.log(response);
      setFormateursData(response.data);
      //const statusCode = response.status;
      //console.log(certificatsdata);
    }); //initialize datatable
  }
 useEffect(() => {
   const timer = setTimeout(() => {
      getallCertiData();
   }, 1000);
   return () => clearTimeout(timer);
 }, []);
    
//JSdataTable
  $(document).ready(function () {
    setTimeout(function () {
      $("#example").DataTable({ 
         paging: true,
    searching: true,
 retrieve: true,
   
          language: {
            processing:     "Traitement en cours...",
            search:         "Rechercher&nbsp;:",
            lengthMenu:     "Afficher _MENU_ &eacute;l&eacute;ments",
            info:           "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
            infoEmpty:      "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
            infoFiltered:   "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
            infoPostFix:    "",
            loadingRecords: "Chargement en cours...",
            zeroRecords:    "Aucun &eacute;l&eacute;ment &agrave; afficher",
            emptyTable:     "Aucune donnée disponible dans le tableau",
            paginate: {
                first:      "Premier",
                previous:   "Pr&eacute;c&eacute;dent",
                next:       "Suivant",
                last:       "Dernier"
            },

      
        }
      }
      );
      
    }, 5000);


    
  });

    //DELETE ATTESTATION

if(certId !==""){
  //console.log("Etes-vous sûr de vouloir supprimer!");
  const confirm= window.confirm("Etes-vous sûr de vouloir supprimer ce formateur !")
   if(confirm===true){
     axios
       .delete(certificatUrl + "/" + certId, {
         headers: {
           "Access-Control-Allow-Origin": "*",
           "Access-Control-Allow-Credentials": true,
           "Content-Type": "application/json",
           Authorization: `Bearer ${localStorage.getItem("user-token")}`,
         },
       })
       .then(function (response) {
         //console.log(response);
         setSuccesmsg("L'attestation a été supprimée avec succès !");
         window.location.href = "./allformateurs";
         //alert(response.data.status);
         //const statusCode = response.status;
         // console.log(statusCode);
       })
       .catch((e) => {
         //const errorServer= console.log(e.response.status);

         setErrormsg(
           "Oouf, une erreur est survenue ! Veuillez signaler l'erreur à l'administrateur. "
         );
       });

     //axios.delete(certificatUrl + "/" + certId);
   }
  if (confirm === false) {
    window.location.href = "./allformateurs";
  }
  }
  
  

  return (
    <>
      <Protedroute />

      <div class={`  ${darkToggle && "bg-gray-900"}`}>
        <aside class=" ml-[-100%] fixed z-10 top-0 pb-3 px-6 w-full flex flex-col justify-between h-screen border-r bg-{dark}  transition duration-300 md:w-4/12 lg:ml-0 lg:w-[25%] xl:w-[20%] 2xl:w-[15%]  ">
          <div>
            <div class="float-right relative">
              <a href="#" title="TABLEAU DE BORD">
                <img
                  src=".././images/CARRE_CINEF.jpeg"
                  class="w-8 rounded-full "
                  alt="tailus logo"
                ></img>
                Connecté
              </a>
            </div>

            <div class=" text-center ">
              <img
                src=".././images/fotor-03d1a91a0cec4542927f53c87e0599f6.webp"
                alt=""
                class="w-10 h-10 m-auto rounded-full object-cover lg:w-28 lg:h-28"
              ></img>
              <h5 class="hidden mt-4 text-sm font-semibold text-gray-600 lg:block">
               {user}
              </h5>
              <span class="hidden text-gray-400 lg:block">
                Gestionnaire
              </span>
            </div>

            <ul class="space-y-2 tracking-wide mt-8">
              <li>
                <a
                  href="#"
                  aria-label="dashboard"
                  class="relative px-4 py-3 flex items-center space-x-4 rounded-xl text-white bg-green-700 from-sky-600 to-cyan-400"
                >
                  <svg class="-ml-1 h-6 w-6" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M6 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V8ZM6 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-1Z"
                      class="fill-current text-cyan-400 dark:fill-slate-600"
                    ></path>
                    <path
                      d="M13 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2V8Z"
                      class="fill-current text-cyan-200 group-hover:text-cyan-300"
                    ></path>
                    <path
                      d="M13 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-1Z"
                      class="fill-current group-hover:text-sky-300"
                    ></path>
                  </svg>
                  <span class="-mr-1 font-medium">DASHBOARD</span>
                </a>
              </li>
              <li>
                <a
                  href="./userdash"
                  class="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5em"
                    height="1.5em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M21 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5v2H5v14h14v-5h2z"
                    />
                    <path
                      fill="currentColor"
                      d="M21 7h-4V3h-2v4h-4v2h4v4h2V9h4z"
                    />
                  </svg>
                  <span class="group-hover:text-gray-700">Attestation +</span>
                </a>
              </li>
              <li>
                <a
                  href="./addformation"
                  class="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5em"
                    height="1.5em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M21 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5v2H5v14h14v-5h2z"
                    />
                    <path
                      fill="currentColor"
                      d="M21 7h-4V3h-2v4h-4v2h4v4h2V9h4z"
                    />
                  </svg>
                  <span class="group-hover:text-gray-700">Thème +</span>
                </a>
              </li>
              <li>
                <a
                  href="./addformateur"
                  class="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5em"
                    height="1.5em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M21 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5v2H5v14h14v-5h2z"
                    />
                    <path
                      fill="currentColor"
                      d="M21 7h-4V3h-2v4h-4v2h4v4h2V9h4z"
                    />
                  </svg>
                  <span class="group-hover:text-gray-700 border-b-2 border-green-500">
                    Consultant +
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="./allattestations"
                  class="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5em"
                    height="1.5em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M15 0H9v7c0 .55.45 1 1 1h1v1h1V8h3c.55 0 1-.45 1-1V1c0-.55-.45-1-1-1zm-4 7h-1V6h1v1zm4 0h-3V6h3v1zm0-2h-4V1h4v4zM4 5H3V4h1v1zm0-2H3V2h1v1zM2 1h6V0H1C.45 0 0 .45 0 1v12c0 .55.45 1 1 1h2v2l1.5-1.5L6 16v-2h5c.55 0 1-.45 1-1v-3H2V1zm9 10v2H6v-1H3v1H1v-2h10zM3 8h1v1H3V8zm1-1H3V6h1v1z"
                    />
                  </svg>
                  <span class="group-hover:text-gray-700 ">Attestations</span>
                </a>
              </li>
            </ul>
          </div>

          <div class="px-6 -mx-6 pt-4 flex justify-between items-center border-t">
            <button
              class="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group"
              onClick={logout}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
              <span class="group-hover:text-gray-700">Déconnexion</span>
            </button>
          </div>
        </aside>
        {/* KPIs */}
        <div class="ml-auto mb-6 lg:w-[75%] xl:w-[80%] 2xl:w-[85%]">
          <div class="sticky z-10 top-0 h-16 border-b bg-white lg:py-2.5">
            <div class="px-6 flex items-center justify-between space-x-4 2xl:container">
              <h5 hidden class="text-2xl text-gray-600 font-bold lg:block">
                TABLEAU DE BORD
              </h5>
              <button class="w-12 h-16 -mr-2 border-r lg:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 my-auto"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>

              {/* Bar seach */}
              <div class="flex space-x-4">
                <div hidden class="md:block">
                  <div class="relative flex items-center text-gray-400  focus-within:text-cyan-400"></div>
                </div>

                <button
                  onClick={() => setDarkToggle(!darkToggle)}
                  aria-label="chat"
                  class="w-10 h-10 rounded-xl border  bg-gray-400 focus:bg-gray-900 active:bg-gray-400"
                >
                  <svg
                    class="mx-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5em"
                    height="1.5em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <g
                      fill="none"
                      stroke="white"
                      stroke-dasharray="2"
                      stroke-dashoffset="2"
                      stroke-linecap="round"
                      stroke-width="2"
                    >
                      <path d="M0 0">
                        <animate
                          fill="freeze"
                          attributeName="d"
                          begin="0.6s"
                          dur="0.2s"
                          values="M12 19v1M19 12h1M12 5v-1M5 12h-1;M12 21v1M21 12h1M12 3v-1M3 12h-1"
                        />
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="0.6s"
                          dur="0.2s"
                          values="2;0"
                        />
                      </path>
                      <path d="M0 0">
                        <animate
                          fill="freeze"
                          attributeName="d"
                          begin="0.9s"
                          dur="0.2s"
                          values="M17 17l0.5 0.5M17 7l0.5 -0.5M7 7l-0.5 -0.5M7 17l-0.5 0.5;M18.5 18.5l0.5 0.5M18.5 5.5l0.5 -0.5M5.5 5.5l-0.5 -0.5M5.5 18.5l-0.5 0.5"
                        />
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="0.9s"
                          dur="1.2s"
                          values="2;0"
                        />
                      </path>
                      <animateTransform
                        attributeName="transform"
                        dur="30s"
                        repeatCount="indefinite"
                        type="rotate"
                        values="0 12 12;360 12 12"
                      />
                    </g>
                    <mask id="svgIDa">
                      <circle cx="12" cy="12" r="12" fill="#fff" />
                      <circle cx="18" cy="6" r="12" fill="#fff">
                        <animate
                          fill="freeze"
                          attributeName="cx"
                          dur="0.4s"
                          values="18;22"
                        />
                        <animate
                          fill="freeze"
                          attributeName="cy"
                          dur="0.4s"
                          values="6;2"
                        />
                        <animate
                          fill="freeze"
                          attributeName="r"
                          dur="0.4s"
                          values="12;3"
                        />
                      </circle>
                      <circle cx="18" cy="6" r="10">
                        <animate
                          fill="freeze"
                          attributeName="cx"
                          dur="0.4s"
                          values="18;22"
                        />
                        <animate
                          fill="freeze"
                          attributeName="cy"
                          dur="0.4s"
                          values="6;2"
                        />
                        <animate
                          fill="freeze"
                          attributeName="r"
                          dur="0.4s"
                          values="10;1"
                        />
                      </circle>
                    </mask>
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      fill="gray"
                      mask="url(#svgIDa)"
                    >
                      <animate
                        fill="freeze"
                        attributeName="r"
                        dur="0.4s"
                        values="10;6"
                      />
                    </circle>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          {/* Body I*/}
          <p class="text-green-700 text-3xl font-bold text-center mt-8 ">
            LISTE DES FORMATEURS
          </p>
          <p class="text-green-500 text-xl text-center mt-4 ">{succesmsg}</p>
          <p class="text-red-500 text-xl text-center mt-4 ">{errormsg}</p>
          {/*Table CDN*/}
          <div class="w-full px-8 pt-4 justify-center mx-auto">
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
              {/*  <div class="p-4">
                <label for="table-search" class="sr-only">
                  Search
                </label>
                <div class="relative mt-1">
                  <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      class="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <input
                    type="text"
                    onChange={(e) => setSearchVal(e.target.value)}
                    id="table-search"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Recherche..."
                  ></input>
                </div>
              </div> */}
              <table
                id="example"
                class="w-full text-sm text-left text-gray-700  dark:text-gray-400"
              >
                <thead class="text-xs  text-gray-500 uppercase bg-white bg-gray-200 p-4 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="p-4 ">
                      <div class="flex items-center"> Réf</div>
                    </th>

                    <th scope="col" class="px-6 py-3">
                      Nom et Prénom(s)
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Thème de la formation
                    </th>

                    <th scope="col" class="px-6 py-3">
                      Titre du formateur
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Action In
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Action Out
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {formateursdata.map((formateur, i) => (
                    <tr
                      key={i}
                      class="bg-white border-b-2 border-gray-200 dark:bg-gray-800 dark:border-gray-700 text-gray-700  hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <td class="px-2 bg-gray-200 ">{formateur._id}</td>
                      <th
                        scope="row "
                        class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap border-b-2 border-gray-200 "
                      >
                        {formateur.fullname}
                      </th>
                      <td class="px-6 py-4 border-b-2 border-gray-200">
                        {formateur.theme}
                      </td>
                      <td class="px-6 py-4 bg-gray-200">{formateur.title}</td>

                      <td class="px-6 py-4">
                        <button
                          onClick={() => setIsOpen(true)}
                          className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2em"
                            height="2em"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 36 36"
                          >
                            <path
                              fill="green"
                              d="M19.5 28.1h-2.9c-.5 0-.9-.3-1-.8l-.5-1.8l-.4-.2l-1.6.9c-.4.2-.9.2-1.2-.2l-2.1-2.1c-.3-.3-.4-.8-.2-1.2l.9-1.6l-.2-.4l-1.8-.5c-.4-.1-.8-.5-.8-1v-2.9c0-.5.3-.9.8-1l1.8-.5l.2-.4l-.9-1.6c-.2-.4-.2-.9.2-1.2l2.1-2.1c.3-.3.8-.4 1.2-.2l1.6.9l.4-.2l.5-1.8c.1-.4.5-.8 1-.8h2.9c.5 0 .9.3 1 .8L21 10l.4.2l1.6-.9c.4-.2.9-.2 1.2.2l2.1 2.1c.3.3.4.8.2 1.2l-.9 1.6l.2.4l1.8.5c.4.1.8.5.8 1v2.9c0 .5-.3.9-.8 1l-1.8.5l-.2.4l.9 1.6c.2.4.2.9-.2 1.2L24.2 26c-.3.3-.8.4-1.2.2l-1.6-.9l-.4.2l-.5 1.8c-.2.5-.6.8-1 .8zm-2.2-2h1.4l.5-2.1l.5-.2c.4-.1.7-.3 1.1-.4l.5-.3l1.9 1.1l1-1l-1.1-1.9l.3-.5c.2-.3.3-.7.4-1.1l.2-.5l2.1-.5v-1.4l-2.1-.5l-.2-.5c-.1-.4-.3-.7-.4-1.1l-.3-.5l1.1-1.9l-1-1l-1.9 1.1l-.5-.3c-.3-.2-.7-.3-1.1-.4l-.5-.2l-.5-2.1h-1.4l-.5 2.1l-.5.2c-.4.1-.7.3-1.1.4l-.5.3l-1.9-1.1l-1 1l1.1 1.9l-.3.5c-.2.3-.3.7-.4 1.1l-.2.5l-2.1.5v1.4l2.1.5l.2.5c.1.4.3.7.4 1.1l.3.5l-1.1 1.9l1 1l1.9-1.1l.5.3c.3.2.7.3 1.1.4l.5.2l.5 2.1zm9.8-6.6z"
                            />
                            <path
                              fill="green"
                              d="M18 22.3c-2.4 0-4.3-1.9-4.3-4.3s1.9-4.3 4.3-4.3s4.3 1.9 4.3 4.3s-1.9 4.3-4.3 4.3zm0-6.6c-1.3 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3c1.3 0 2.3-1 2.3-2.3s-1-2.3-2.3-2.3z"
                            />
                            <path
                              fill="green"
                              d="M18 2c-.6 0-1 .4-1 1s.4 1 1 1c7.7 0 14 6.3 14 14s-6.3 14-14 14S4 25.7 4 18c0-2.8.8-5.5 2.4-7.8v1.2c0 .6.4 1 1 1s1-.4 1-1v-5h-5c-.6 0-1 .4-1 1s.4 1 1 1h1.8C3.1 11.1 2 14.5 2 18c0 8.8 7.2 16 16 16s16-7.2 16-16S26.8 2 18 2z"
                            />
                            <path fill="none" d="M0 0h36v36H0z" />
                          </svg>
                        </button>
                      </td>
                      <td class="px-6 py-4 text-right">
                        <button
                          onClick={() => setCertId(formateur._id)}
                          class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2em"
                            height="2em"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="red"
                              d="m9.4 16.5l2.6-2.6l2.6 2.6l1.4-1.4l-2.6-2.6L16 9.9l-1.4-1.4l-2.6 2.6l-2.6-2.6L8 9.9l2.6 2.6L8 15.1ZM7 21q-.825 0-1.412-.587Q5 19.825 5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413Q17.825 21 17 21Z"
                            />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
                  
              <div>
                      
                {isOpen && (
                  <div>
                            
                    <div className="container text-center text-gray-500 font-bold">
                                *Pour la sécurité des données en cas d'erreur,
                      nous vous conseillons de supprimer et de resaisir les
                      données.         
                    </div>
                            
                    <button
                      onClick={() => setIsOpen(false)}
                      className="font-medium text-white border p-4 w-30 h-15 float-right mb-4 mr-4 rounded-lg bg-red-500 dark:text-blue-500 "
                    >
                      Fermer
                    </button>
                           
                  </div>
                )}
                    
              </div>
            </div>

            <script src="https://unpkg.com/flowbite@1.3.4/dist/flowbite.js"></script>
          </div>

          {/* Body II*/}
        </div>

        {/* MODAL*/}

        {/* MODAL*/}
        <footer class="relative bg-blueGray-50 pt-8 pb-6 mt-48">
          <div class="container mx-auto px-4">
            <div class="flex flex-wrap items-center md:justify-between justify-center">
              <div class="w-full md:w-6/12 px-4 mx-auto text-center">
                <div class="text-sm text-blueGray-500 font-semibold py-1 bg-white rounded-full">
                  CopyRight@{" "}
                  <a
                    href="https://www.creative-tim.com/product/notus-js"
                    class="text-blueGray-500 hover:text-gray-800"
                    target="_blank"
                  >
                    CINEF SAS
                  </a>{" "}
                  by{" "}
                  <a
                    href="/"
                    class="text-blueGray-500 hover:text-blueGray-800"
                    target="_blank"
                  >
                    {" "}
                    Cotonou - Bénin
                  </a>
                  .
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

