import TypeWriterEffect from "react-typewriter-effect";
import React, { Text, useState } from "react";
import Modal from "./Modal";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { QRCodeCanvas } from "qrcode.react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import "./Appcertificat.css";
import axios from "axios";
import { isMobile } from "react-device-detect";

export default function App() {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const formAPI = process.env.React_App_Uri_api + "formations";
  const downloadUrl = process.env.React_App_Uri_api + "downloads";
  const navigate = useNavigate();
  const certiData = useLocation();
  const [disabled, setDisabled] = useState(false);
  //console.log(certiData.state);
  const [loading, setLoading] = useState(false);

  //Function onclick loading button

  const handleClick = () => {
    setLoading(true);
    // Perform operation and then set loading to false
    setTimeout(() => {
      handleDownloadPdf();
      setLoading(false);
    }, 2000);
  };

  const MobilehandleClick = () => {
    setDisabled(true);
    setLoading(false);
    // Perform operation and then set loading to false
    setTimeout(() => {
      // handleDownloadPdf();
      MobilehandleDownloadPdf();
      setDisabled(false);
    }, 2000);
  };

  //date
  const current = new Date();
  const ondate = current.toLocaleString();
  //const ondate = Date();
  //console.log(ondate);

  //SAVE RENDER
  const downloadDATA = {
    reference: certiData.state.reference,
    fullname: certiData.state.fullname,
    theme: certiData.state.theme,
    time: ondate,
  };

  const JsondownloadDATA = JSON.stringify(downloadDATA);

  const download = () => {
    axios
      .post(downloadUrl, JsondownloadDATA, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Content-Type": "application/json",
          //Authorization: `Bearer ${localStorage.getItem("user-token")}`,
        },
      })
      .then(function (response) {
        //console.log(response);
        //setCertificatsData(response.data);
        //const statusCode = response.status;
        //console.log(certificatsdata);
      }); //initialize datatable
  };

  //console.log(JsondownloadDATA);

  //SAVE RENDER

  //QR GENERATE

  if (
    certiData.state === null ||
    certiData.state == undefined ||
    !certiData.state
  ) {
    {
      /* alert("No match, la session a expirée. Veuillez flacher à nouveau et rééssayer !");
     navigate("./confirmationducode");
   } */
    }
    if (
      window.confirm(
        "No match, la session a expirée. Veuillez flacher à nouveau et rééssayer !"
      )
    ) {
      // Save it!
      //console.log("Heloo");

      window.location.href = "/";
    }
  }

  const [url, setUrl] = useState("");
  const printRef = React.useRef();
  const text = "https://cinef-certificate.com/confirmationducode";

  const qrcode = (
    <QRCodeCanvas
      id="qrCode"
      value={text}
      size={100}
      bgColor={"#ffffff"}
      level={"H"}
    />
  );

  // DESABLED RIGHT MOUSSE BUTTON
  document.addEventListener("contextmenu", (event) => event.preventDefault());
  document.addEventListener("keyup", function (e) {
    var keyCode = e.keyCode ? e.keyCode : e.which;
    if (keyCode == 44) {
      stopPrntScr();
    }
  });
  function stopPrntScr() {
    var inpFld = document.createElement("input");
    inpFld.setAttribute("value", ".");
    inpFld.setAttribute("width", "0");
    inpFld.style.height = "0px";
    inpFld.style.width = "0px";
    inpFld.style.border = "0px";
    document.body.appendChild(inpFld);
    inpFld.select();
    document.execCommand("copy");
    inpFld.remove(inpFld);
  }
  function AccessClipboardData() {
    try {
      window.clipboardData.setData("text", "Access   Restricted");
    } catch (err) {}
  }
  //setInterval("AccessClipboardData()", 300);

  // PDF GENERATE B
  //const printRef = React.useRef();
  const handleDownloadPdf = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    var pdf = new jsPDF("l", "mm", [297, 210]);

    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(downloadDATA.reference + "cinef_attestation.pdf");
  };

  // Mobile Print

  const printMobileRef = React.useRef();
  const MobilehandleDownloadPdf = async () => {
    const Mobileelement = printMobileRef.current;
    const canvas = await html2canvas(Mobileelement);
    const data = canvas.toDataURL("image/png");

    var pdf = new jsPDF("l", "mm", [297, 210]);

    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(downloadDATA.reference + "cinef_attestation.pdf");
  };

  return (
    <>
      {AccessClipboardData()}
      <div class="area">
        <ul class="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>

        <div class="bg-green-900 dark:bg-gray-800 flex items-center">
          <div class="mx-auto px-6 flex flex-col justify-between items-center ">
            <div class="flex flex-col ">
              <h1 class="font-bold w-full uppercase text-center mt-4 text-4xl sm:text-5xl dark:text-white text-white">
                ATTESTATION DE FORMATION NUMERIQUE DE CINEF
              </h1>
              <h2 class="font-light max-w-2xl mx-auto  text-xl dark:text-white text-white text-center py-4 ">
                Nos attestations/certificats sont digitalisé(e)s et offrent une
                facilité d'utilisation et de vérification n'importe ou et
                n'importe quand.
              </h2>
            </div>

            {/*ATTESTATION RENDER*/}

            <div className="w-full h-full p-4 ">
              {/*content*/}
              <div className="flex flex-col ">
                {/*header*/}
                {/* Mobile  Print */}
                {isMobile ? (
                  <div>
                    <div
                      onLoad={download}
                      id="printablediv"
                      ref={printRef}
                      className="mobilebg  w-[297mm] h-[210mm] border-8 outline-8 md:border-green-700 text-center border-green-700 bg-no-repeat bg-center"
                    >
                      <div class="head space-y-2  flex justify-between -mb-32 ">
                        <img
                          src=".././images/Logocinef.svg"
                          class=" -mt-8 h-64 w-80  p-2 "
                          alt="CINEF LOGO"
                        />
                        {/*<div class="px-4 mx-auto ">
                          <img
                            src=".././images/attestation-de-reussite-CINEF.jpeg"
                            class="font-bold w-32 mx-auto  "
                            alt="Logo CINEF"
                          />
                        </div>*/}
                        <img
                          src=".././images/frame.png"
                          class="font-bold w-32 h-28 pb-0 p-4 "
                          alt="Qr"
                        />
                      </div>
                      <div class="text-red-600 mt-6 -mb-24 font-bold float-right text-[11.5px] mr-6  leading-none hover:leading-loose">
                        Réf: {certiData.state.reference}
                      </div>
                      <h5 class="mb-2 mt-8 ml-16 text-5xl font-bold  items-center pl-16 text-green-700 dark:text-white">
                        ATTESTATION DE FORMATION
                      </h5>
                      <p class="mb-2 text-base items-center justify-center font-bold text-gray-900 sm:text-lg dark:text-gray-400">
                        Nous attestons que:
                      </p>
                      <p class="myname mb-0 font-sans-system-ui text-3xl font-bold text-green-900  dark:text-gray-400">
                        {certiData.state.fullname}
                      </p>
                      <p class="">de:</p>
                      <p class="mb-2 text-base text-sm font-bold text-gray-900 sm:text-lg dark:text-gray-400">
                        {certiData.state.structure}
                      </p>
                      <div class="mb-2 text-base text-sm font-bold text-gray-900 sm:text-sm dark:text-gray-400">
                        a suivi avec succès la formation
                      </div>
                      <p className="p-2  border-b-2 border-t-2  border-green-700 justify-center mx-48  items-center text-center  leading-normal uppercase font-bold text-[#AA3F54]  dark:text-gray-400">
                        " {certiData.state.theme} "
                      </p>
                      <div class="text-base  -mb-8 text-sm font-bold text-[#3F3FAA] sm:text-sm dark:text-gray-400">
                        {certiData.state.dateon} | {certiData.state.lieu} |{" "}
                        {certiData.state.typeformation}
                      </div>{" "}
                      <div className=" text-gray-500 mr-8 mt-12  grid justify-items-end font-bold text-sm ">
                        <img
                          src=".././images/Cahet-sign.png"
                          class="w-48 "
                          alt="CINEF SIGNATURE"
                        />
                        DEDJI Wilfrid, Président CINEF SAS
                      </div>
                      <div class="text-left text-gray-500 -mt-24 ">
                        <div class="mb-1 text-xs underline">
                          Instructeur(s):
                        </div>
                        <div class="-mt-1 font-sans pb-2 text-sm font-semibold ">
                          <p>
                            {certiData.state.intervenant} :{" "}
                            {certiData.state.titre}
                          </p>
                          <p>
                            {certiData.state.intervenant2} :{" "}
                            {certiData.state.titre2}
                          </p>
                        </div>
                        <div className="att -mt-2 ">
                          <p class=" -mb-12 text-base text-sm font-bold text-center text-gray-900 sm:text-sm dark:text-gray-400">
                            Imprimé : le {ondate}
                          </p>
                          <p class="mb-1 mt-12 text-base text-sm text-center font-bold text-gray-400 sm:text-sm dark:text-gray-400">
                            {/* CINEF SAS | COTONOU - BENIN | Tél. : +229 63 18 60 60 /
                        97 08 46 01 | E-mail: info@cinef-bj.com | Site web :
                      www.cinef-bj.com*/}
                            CINEF SAS | COTONOU - BENIN<br></br>
                            www.cinef-bj.com | www.cinef-certificate.com
                            <br></br>
                            Tél.: +229 63 18 60 60 - 97 08 46 01 | E-mail:
                            info@cinef-bj.com
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-end p-6  border-solid border-slate-200 rounded-b">
                      <a
                        href="/"
                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                      >
                        Fermer
                      </a>
                      <button
                        type="button"
                        disabled={disabled}
                        className={`bg-green-700 hover:bg-gray-700 ml-4 text-white py-2 px-4 border border-white rounded}`}
                        onClick={() => {
                          // handleClick();
                          setLoading(false);
                          // MobilehandleDownloadPdf();
                          // MobilehandleClick();
                          handleDownloadPdf();
                        }}
                        // enable={loading}
                      >
                        Télécharger sur mon portable
                      </button>{" "}
                    </div>
                    {/* " Papa" */}
                  </div>
                ) : (
                  <div>
                    {/* Desktop print  */}
                    <div>
                      <div
                        onLoad={download}
                        id="printablediv"
                        ref={printRef}
                        className="mobilebg  w-[297mm] h-[210mm] border-8 outline-8 md:border-green-700 text-center border-green-700 bg-no-repeat bg-center"
                      >
                        <div class="head space-y-2  flex justify-between -mb-32 ">
                          <img
                            src=".././images/Logocinef.svg"
                            class=" -mt-8 h-64 w-80  p-2 "
                            alt="CINEF LOGO"
                          />
                          {/*<div class="px-4 mx-auto ">
                          <img
                            src=".././images/attestation-de-reussite-CINEF.jpeg"
                            class="font-bold w-32 mx-auto  "
                            alt="Logo CINEF"
                          />
                        </div>*/}
                          <img
                            src=".././images/frame.png"
                            class="font-bold w-32 h-28 pb-0 p-4 "
                            alt="Qr"
                          />
                        </div>
                        <div class="text-red-600 mt-6 -mb-24 font-bold float-right text-[11.5px] mr-6  leading-none hover:leading-loose">
                          Réf: {certiData.state.reference}
                        </div>
                        <h5 class="mb-2 mt-8 ml-16 text-5xl font-bold  items-center pl-16 text-green-700 dark:text-white">
                          ATTESTATION DE FORMATION
                        </h5>
                        <p class="mb-2 text-base items-center justify-center font-bold text-gray-900 sm:text-lg dark:text-gray-400">
                          Nous attestons que:
                        </p>
                        <p class="myname mb-0 font-sans-system-ui text-3xl font-bold text-green-900  dark:text-gray-400">
                          {certiData.state.fullname}
                        </p>
                        <p class="">de:</p>
                        <p class="mb-2 text-base text-sm font-bold text-gray-900 sm:text-lg dark:text-gray-400">
                          {certiData.state.structure}
                        </p>
                        <div class="mb-2 text-base text-sm font-bold text-gray-900 sm:text-sm dark:text-gray-400">
                          a suivi avec succès la formation
                        </div>
                        <p className="p-2  border-b-2 border-t-2  border-green-700 justify-center mx-48  items-center text-center  leading-normal uppercase font-bold text-[#AA3F54]  dark:text-gray-400">
                          " {certiData.state.theme} "
                        </p>
                        <div class="text-base  -mb-8 text-sm font-bold text-[#3F3FAA] sm:text-sm dark:text-gray-400">
                          {certiData.state.dateon} | {certiData.state.lieu} |{" "}
                          {certiData.state.typeformation}
                        </div>{" "}
                        <div className=" text-gray-500 mr-8 mt-12  grid justify-items-end font-bold text-sm ">
                          <img
                            src=".././images/Cahet-sign.png"
                            class="w-48 "
                            alt="CINEF SIGNATURE"
                          />
                          DEDJI Wilfrid, Président CINEF SAS
                        </div>
                        <div class="text-left text-gray-500 -mt-24 ">
                          <div class="mb-1 text-xs underline">
                            Instructeur(s):
                          </div>
                          <div class="-mt-1 font-sans pb-2 text-sm font-semibold ">
                            <p>
                              {certiData.state.intervenant} :{" "}
                              {certiData.state.titre}
                            </p>
                            <p>
                              {certiData.state.intervenant2} :{" "}
                              {certiData.state.titre2}
                            </p>
                          </div>
                          <div className="att -mt-2 ">
                            <p class=" -mb-12 text-base text-sm font-bold text-center text-gray-900 sm:text-sm dark:text-gray-400">
                              Imprimé : le {ondate}
                            </p>
                            <p class="mb-1 mt-12 text-base text-sm text-center font-bold text-gray-400 sm:text-sm dark:text-gray-400">
                              {/* CINEF SAS | COTONOU - BENIN | Tél. : +229 63 18 60 60 /
                        97 08 46 01 | E-mail: info@cinef-bj.com | Site web :
                      www.cinef-bj.com*/}
                              CINEF SAS | COTONOU - BENIN<br></br>
                              www.cinef-bj.com | www.cinef-certificate.com
                              <br></br>
                              Tél.: +229 63 18 60 60 - 97 08 46 01 | E-mail:
                              info@cinef-bj.com
                            </p>
                          </div>
                        </div>
                      </div>
                     
                      {/* " Papa" */}
                    </div>

                    <div className="flex items-center justify-end p-6  border-solid border-slate-200 rounded-b">
                      <a
                        href="/"
                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                      >
                        Fermer
                      </a>
                      <button
                        type="button"
                        className={`bg-green-700 hover:bg-gray-700 text-white py-2 px-4 border border-white rounded ${
                          loading ? "cursor-not-allowed opacity-25" : ""
                        }`}
                        onClick={() => {
                          handleClick();
                        }}
                        enable={loading}
                      >
                        {loading ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 animate-spin mx-auto"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                            />
                          </svg>
                        ) : (
                          "Télécharger sur mon Desktop"
                        )}
                      </button>{" "}
                    </div>
                    {/* "Yes" */}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/*ATTESTATION RENDER*/}
        </div>
      </div>
    </>
  );
}
