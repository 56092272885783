import React from "react";
import Header from "./Header";
import  { Text, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";

export default function Passwordforgot() {

const [email, setEmail] = React.useState("");
const [succesmsg, setSuccesmsg] = React.useState("");
const [errormsg, setErrormsg] = React.useState("");
const userUrl = process.env.React_App_Uri_api;

 //Onsubmit form

  const handleSubmit = async (event) => {
    //Prevent page reload
    event.preventDefault();
    var { email } = document.forms[0];

    const userEmail = email.value; 


axios.get(userUrl+"auth/"+userEmail, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
      "Content-Type": "application/json",
      //  "Authorization": `Bearer ${localStorage.getItem("user-token")}`,
    },
  })
  .then(function (response) {
     //console.log(response);
    //console.log(userEmail);
    //console.log(response.data.data.email);
    if (response.data.data.email === userEmail) {
      let person = prompt(
        "Veuillez saisir un nouveau mot de passe ",
        "********"
      );
      if (person != null) {
        const DataUpdate = JSON.stringify({
          fullname: response.data.data.fullname,
          email: response.data.data.email,
          password: person,
        });
        //console.log(DataUpdate);
        //console.log(response.data.data._id);
        //UPDATE Query
        axios
          .patch(userUrl + response.data.data._id, DataUpdate, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              "Content-Type": "application/json",
              //"Authorization": `Bearer ${localStorage.getItem("user-token")}`,
            },
          })
          .then(function (response) {
            //console.log(response);

            setSuccesmsg(
              "Super, votre mot de passe a été réinitialisé avec succès !"
            );
          });
        //document.getElementById("demo").innerHTML =
        // "Hello " + person + "! How are you today?";
      }
    }
    //console.log(response.data.data.email);
    if (response.data.data.email !== userEmail) {
      setErrormsg(
        "Désolé, cette adresse email n'existe pas dans notre régistre !"
      );
    }
    //setSuccesmsg(response.data.message);
    //const statusCode = response.status;
    // console.log(statusCode);
  })
  .catch((e) => {
    //const errorServer= console.log(e.response.status);

    setErrormsg(
      "Oouf mauvais email, veuillez vérifier votre email et rééssayer. ! "
    );
  });
    
  };


  return (
    <>
      <Header />

      <main id="content" role="main" class="w-full max-w-md mx-auto p-6">
        <div class="mt-7 bg-white  rounded-xl shadow-lg dark:bg-gray-800 dark:border-gray-700">
          <div class="p-4 sm:p-7">
            <div class="text-center">
              <h1 class="block text-2xl font-bold text-gray-800 dark:text-white">
                Mot de passe oublié ?
              </h1>
              <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
                Mot de passe retrouvé ? &nbsp;
                <a
                  class="text-blue-600 decoration-2 hover:underline font-medium"
                  href="/login"
                >
                  Se connecter ici
                </a>
              </p>
            </div>
            <p class="text-green-500 text-xl text-center mt-4 ">{succesmsg}</p>
            <p class="text-red-500 text-xl text-center mt-4 ">{errormsg}</p>

            <div class="mt-5">
              <form name="changepass" onSubmit={handleSubmit} method="post">
                <div class="grid gap-y-4">
                  <div>
                    <label
                      for="email"
                      class="block text-sm font-bold ml-1 mb-2 dark:text-white"
                    >
                      Email address
                    </label>
                    <div class="relative">
                      <input
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        id="email"
                        name="email"
                        class="py-3 px-4 block w-full border-2 border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 shadow-sm"
                        required
                        aria-describedby="email-error"
                        placeholder="example@example.com"
                      />
                    </div>
                    <p
                      class="hidden text-xs text-red-600 mt-2"
                      id="email-error"
                    >
                      Please include a valid email address so we can get back to
                      you
                    </p>
                  </div>
                  <button
                    type="submit"
                    class="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-green-700 border-transparent font-semibold bg-green-700 text-green-700 border-2 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                  >
                    {" "}
                    Réinitialiser le mot de passe
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <p class="mt-3 flex justify-center items-center text-center divide-x divide-gray-300 dark:divide-gray-700"></p>
      </main>
    </>
  );
}
